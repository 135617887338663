import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import SignupPage from './components/SignupPage';
import { Analytics } from "@vercel/analytics/react";

const App: React.FC = () => {
  return (
    <Router>
      <div className="bg-cream text-charcoal">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/signup" element={<SignupPage />} />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
};

export default App;