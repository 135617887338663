import React, { useState, useEffect, FC, useRef } from 'react';
import { motion } from 'framer-motion';

const Main: FC = () => {
  const [age, setAge] = useState<number>(0);
  const [llmHours, setLlmHours] = useState<number>(2322);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [rocketPosition, setRocketPosition] = useState({ x: -50, y: 0 });
  const [rocketDirection, setRocketDirection] = useState(1);
  const rocketRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Set specific start date: February 26, 2025
    const startDate = new Date('2025-02-26T00:00:00');
    const startingHours = 2322;
    
    const updateLLMHours = () => {
      const now = new Date();
      // Calculate time difference in milliseconds
      const timeDiffMs = now.getTime() - startDate.getTime();
      
      // Convert to days (1/5 of a day = 0.2 days)
      const daysPassed = timeDiffMs / (1000 * 60 * 60 * 24);
      
      // We want to increase by 1 hour every 1/5 of a day (4.8 hours)
      // So we multiply days by 5 to get the hourly increase
      const additionalHours = daysPassed * 5;
      
      // Calculate new total (base + increase)
      const newHours = startingHours + additionalHours;
      
      // Update state with 1 decimal place for smooth visual
      setLlmHours(+newHours.toFixed(1));
    };
    
    // Initial update
    updateLLMHours();
    
    // Update every second for a smooth counter
    const llmInterval = setInterval(updateLLMHours, 1000);
    
    return () => clearInterval(llmInterval);
  }, []);

  useEffect(() => {
    const birthday = new Date('2002-02-28');
    const updateAge = () => {
      const ageInMilliseconds = Date.now() - birthday.getTime();
      const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      setAge(ageInYears);
    };
    updateAge();
    const ageInterval = setInterval(updateAge, 50);

    const updateDate = () => {
      const now = new Date();
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(now).toUpperCase();
      setCurrentDate(formattedDate);
    };
    updateDate();
    const dateInterval = setInterval(updateDate, 1000 * 60);

    // Animate rocket
    const animateRocket = () => {
      let x = -50;
      let direction = 1;
      let y = 0;
      let angle = 0;

      const animate = () => {
        x += 1 * direction;
        angle += 0.05;
        y = Math.sin(angle) * 10;

        if (x > window.innerWidth) {
          direction = -1;
        } else if (x < -50) {
          direction = 1;
        }

        setRocketPosition({ x, y });
        setRocketDirection(direction);
        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    };

    animateRocket();

    return () => {
      clearInterval(ageInterval);
      clearInterval(dateInterval);
    };
  }, []);

  return (
    <div className="bg-white min-h-screen p-4 sm:p-8 font-lexend font-medium tracking-tight">
      <motion.div 
        ref={rocketRef} 
        className="rocket absolute z-10 text-2xl"
        style={{ 
          x: rocketPosition.x, 
          y: rocketPosition.y,
          rotate: Math.sin(rocketPosition.x * 0.01) * 10,
          scaleX: rocketDirection
        }}
      >
        🚀
      </motion.div>

      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <div className="text-center mb-4">
          <h1 className="font-lexend text-4xl sm:text-6xl text-gray-800 mb-4 font-semibold">
            Ethan Kinnan
          </h1>
          <div className="flex flex-wrap justify-center items-center space-x-2 sm:space-x-6 text-xs sm:text-sm text-gray-600 font-lexend">
            <span>Age: {age.toFixed(4)}</span>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-8 text-gray-700 text-sm sm:text-base font-lexend">
          {/* Leading Quote - Now at the very top */}
          <div className="bg-blue-100 p-4 border-l-4 border-blue-500 my-4 text-base">
            <span className="font-bold">A human with AI can do anything.</span> This fundamental belief drives everything I do.
          </div>
          


          {/* Who is Ethan section */}
          <h2 className="text-2xl font-semibold underline underline-offset-4 mb-2">Who is Ethan?</h2>
          
          <p className="leading-relaxed text-black font-medium tracking-tighter text-base">
            Remember that kid who wouldn't stop asking questions? Yeah, I'm still here… just a little taller. 
            
            <br/><br/>Luckily for everyone else, I found AI - computers never get tired of questions or crazy ideas. <span className="font-bold">{Math.floor(llmHours)}</span> hours talking to LLMs later, I'm still asking questions.
          </p>

          <div className="relative bg-white border-2 border-gray-300 rounded-lg p-6 mt-6 mb-4">
            <span className="absolute -top-3 left-4 bg-white px-2 text-xs font-semibold text-black">
              Currently
            </span>
            <p className="text-black font-medium tracking-tighter text-base">
              Building enterprise AI agents and helping others do the same at{' '}
              <a 
                href="https://www.lindy.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300"
              >
                Lindy
              </a>.
            </p>
          </div>
          
          <p className="leading-relaxed text-black font-medium tracking-tighter text-base">
            In other words, I make workflows more efficient or completely reinvent them with AI. I run creative AI experiments, translate business processes into AI automations, and build new things every day.
            
            <br/><br/>I recently moved to San Francisco to be in person around people building really cool B2B autonomous AI systems.
            
            <br/><br/>Previously, I was an AI Automation Engineer for{' '}
              <a 
                href="https://www.alliekmiller.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300"
              >
                Allie K. Miller
              </a>. A few of my favorite projects there were an AI data analyst that suggested optimizations based on metrics and a self-researching AI that evaluated 100+ multi-agentic frameworks daily.
          </p>

          <p className="leading-relaxed text-black font-medium tracking-tighter text-base">
            Before the AI obsession, I had a habit of getting jobs at financial data firms like             <a href="https://standardmetrics.io" target="_blank" rel="noopener noreferrer" className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300">Standard Metrics</a> and <a href="https://pitchbook.com" target="_blank" rel="noopener noreferrer" className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300">PitchBook</a> and automating the repetitive parts away. After building an AI financial doc parser with GPT 3.5, it started to click… I began using AI everywhere all the time. 
            
            <br/><br/>GPT 4o became my college professor and saved me signficant time. With that time, I failed building an AI financial planner with my two closest friends (don't worry, we'll be back 🫡). 
            
            <br/><br/>After Claude Sonnet 3.5 came out, I started learning more about computers and built a new web app every day like{' '}
            <a 
              href="https://www.youtube.com/embed/7pN9308VnNw"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300"
            >
              rabbithole.ai
            </a>{' '}
            and{' '}
            <a 
              href="https://emojifactory.io"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#3b82f6] hover:text-[#2563eb] underline transition-colors duration-300"
            >
              genmoji
            </a>{' '}
             (before Apple did it lol). I kept experimenting until I found a place to work that was as AI-first as me.
          </p>

          <p className="leading-relaxed text-black font-medium tracking-tighter text-base">
          The proof is everywhere in my life — AI taught me how to build a production data pipeline in 2 weeks, saves me hundreds of hours each month, and even helped me narrow down my dog search. I also recently watched my friend go from zero coding to building a website from scratch and navigating terminal commands in 48 hours.
          </p>

          <p className="leading-relaxed text-black font-medium tracking-tighter text-base">
            <strong className="font-bold">I want to push AI performance and accessibility so more people can create new things that have never been possible, and help others feel the same magic that I feel. Not 100% sure what this looks like yet, but if I stay consistent I know I'll figure it out.</strong>
          </p>

          {/* Images section with three pictures */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12 mb-8">
            {/* Image 1 */}
            <div className="relative rounded-lg overflow-hidden bg-black">
              <img 
                src="/natty.jpg" 
                alt="CFB national championship" 
                className="w-full h-full object-cover aspect-video opacity-95"
              />
              <div className="absolute bottom-0 left-0 right-0 p-2 bg-black/40">
                <p className="text-white text-sm font-lexend font-medium tracking-tighter">CFB national championship 🏆</p>
              </div>
            </div>
            
            {/* Image 2 */}
            <div className="relative rounded-lg overflow-hidden bg-black">
              <img 
                src="/building.jpg" 
                alt="Trying to build in pre Sonnet 3.5 era" 
                className="w-full h-full object-cover aspect-video opacity-95"
              />
              <div className="absolute bottom-0 left-0 right-0 p-2 bg-black/40">
                <p className="text-white text-sm font-lexend font-medium tracking-tighter">Trying to build in pre Sonnet 3.5 era</p>
              </div>
            </div>
            
            {/* Image 3 */}
            <div className="relative rounded-lg overflow-hidden bg-black">
              <img 
                src="/golf.jpg" 
                alt="My favorite sport golf" 
                className="w-full h-full object-cover aspect-video opacity-95"
              />
              <div className="absolute bottom-0 left-0 right-0 p-2 bg-black/40">
                <p className="text-white text-sm font-lexend font-medium tracking-tighter">My favorite sport 🏌️‍♂️</p>
              </div>
            </div>
          </div>
      
          <div className="flex justify-center space-x-6 mt-8 mb-4">
            <a 
              href="https://x.com/ethan_kinnan"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-[#3b82f6] transition-colors duration-300 flex items-center justify-center w-12 h-12 rounded-full border-2 border-gray-300 hover:border-[#3b82f6]"
            >
              <span className="text-xl font-bold">𝕏</span>
            </a>
            <a 
              href="https://www.linkedin.com/in/ethan-kinnan-59ba471bb/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-[#3b82f6] transition-colors duration-300 flex items-center justify-center w-12 h-12 rounded-full border-2 border-gray-300 hover:border-[#3b82f6]"
            >
              <span className="text-xl font-bold">in</span>
            </a>
            <a 
              href="mailto:ethankinnan@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-[#3b82f6] transition-colors duration-300 flex items-center justify-center w-12 h-12 rounded-full border-2 border-gray-300 hover:border-[#3b82f6]"
            >
              <span className="text-xl">✉️</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;