import React, { useState, useEffect } from 'react';
import { Sun, Moon } from 'lucide-react';

const LandingPage = () => {
  const [isDark, setIsDark] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isVisible, setIsVisible] = useState(Array(6).fill(false));

  const testimonials = [
    {
      text: "Ethan is an AI wizard",
      author: "Software Engineer - D.T."
    },
    {
      text: "Finally found AI tips that don't suck. Using these daily for my consulting work.",
      author: "Strategy Lead - R.D."
    },
    {
        text: "Went from fighting with AI to having it actually help. Worth it just for the automation tricks.",
        author: "Product Manager  - S.L"
      },
      {
        text: "Each week I learn something new that makes me better at my job. No fluff, just practical stuff that works.",
        author: "Growth Lead  - H.K"
      },
      {
        text: "Got a prompt that I still use in my caption automation",
        author: "Content Creator  - C.R."
      }
  ];

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  useEffect(() => {
    const timeouts = isVisible.map((_, index) => {
      return setTimeout(() => {
        setIsVisible(prev => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }, index * 500);
    });

    return () => timeouts.forEach(clearTimeout);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial(prev => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={`min-h-screen ${isDark ? 'bg-slate-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Theme Toggle */}
      <div className="absolute top-4 right-4">
        <button
          onClick={() => setIsDark(!isDark)}
          className={`w-16 h-8 rounded-full relative flex items-center ${
            isDark ? 'bg-slate-700' : 'bg-gray-200'
          }`}
        >
          <span className={`flex items-center justify-center absolute w-6 h-6 rounded-full shadow-md transform duration-300 ${
            isDark ? 'translate-x-9 bg-slate-900' : 'translate-x-1 bg-white'
          }`}>
            {isDark ? 
              <Moon className="w-4 h-4 text-yellow-500" /> : 
              <Sun className="w-4 h-4 text-yellow-500" />
            }
          </span>
        </button>
      </div>

      {/* New Header */}
      <div className="pt-20 pb-8 text-center">
        <div className="max-w-2xl mx-auto px-4 space-y-3">
          <h1 className="text-3xl font-bold">
            Learn how to actually use AI
          </h1>
          <p className={`text-xl ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
            No BS. Just what works.
          </p>
          <div className="flex items-center justify-center space-x-2 text-base">
            <span className={isDark ? 'text-gray-400' : 'text-gray-500'}>One actionable text per week</span>
            <span className={isDark ? 'text-gray-600' : 'text-gray-300'}>•</span>
            <a 
              href="https://ethankinnan.com" 
              className="text-emerald-600 dark:text-emerald-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              More info about me here
            </a>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-2xl mx-auto px-4 space-y-6">
        {/* Messages */}
        <div className="space-y-4">
          {[
            { text: "👋 Hey! I'm Ethan", isMe: true },
            { text: "I spend more time talking to AI than humans (yeah, seriously)", isMe: true },
            { text: "wait what? that's kinda weird lol", isMe: false },
            { text: "never said it wasn't 😅 but it's how I 5x'd my productivity and got hired to build autonomous AI systems", isMe: true },
            { text: "ok you got me - what's one thing I should actually be doing with AI rn?", isMe: false },
            { text: "I'll do you one better - let me send you one text a week with exactly that kind of stuff", isMe: true }
          ].map((message, index) => (
            <div
              key={index}
              className={`flex ${message.isMe ? 'justify-end' : 'justify-start'} ${
                isVisible[index] ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
              } transition-all duration-500`}
            >
              <div className={`max-w-md rounded-lg p-3 ${
                message.isMe 
                  ? 'bg-emerald-600 text-white' 
                  : `${isDark ? 'bg-white/5' : 'bg-gray-100'}`
              }`}>
                {message.text}
              </div>
            </div>
          ))}
        </div>

        {/* Sign Up Form */}
        <div className={`rounded-lg p-6 space-y-4 ${isDark ? 'bg-white/5' : 'bg-gray-100'}`}>
          <input
            type="tel"
            placeholder="(555) 555-5555"
            className={`w-full p-3 rounded-lg border ${
              isDark 
                ? 'bg-slate-800 border-slate-700 text-white' 
                : 'bg-white border-gray-200 text-gray-900'
            }`}
          />
          <button className="w-full bg-orange-500 hover:bg-orange-600 text-white p-3 rounded-lg">
            {'>'} Sign Up
          </button>
          <p className={`text-sm text-center ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
            One text a week. Unsubscribe anytime.
          </p>
        </div>

        {/* What to Expect */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold flex items-center">
            <span className="text-orange-500 mr-2"></span>
            <span>What you can expect:</span>
          </h2>
          <div className="space-y-3">
            {[
              "Best tools and prompts from someone who's used AI daily for almost 2 years",
              "Each week get a beginner and an advanced tip",
              "Accelerate your AI learning curve",
              "You will recieve my top 3 AI tools as soon as you sign up"
            ].map((text, index) => (
              <div 
                key={index} 
                className={`flex items-center p-4 rounded-lg ${isDark ? 'bg-white/5' : 'bg-gray-50'}`}
              >
                <span className="text-orange-500 font-mono mr-3">🎯</span>
                <span className="font-semibold">{text}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Testimonials */}
        <div className={`relative rounded-lg p-6 min-h-[150px] ${isDark ? 'bg-white/5' : 'bg-gray-50'}`}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`absolute inset-0 p-6 ${
                currentTestimonial === index 
                  ? 'opacity-100' 
                  : 'opacity-0'
              } transition-opacity duration-500`}
            >
              <p className="italic mb-3">{testimonial.text}</p>
              <p className="font-medium text-orange-500">
                {testimonial.author}
              </p>
            </div>
          ))}
        </div>

        {/* Back to website link */}
        <div className="text-center py-8">
          <a 
            href="https://ethankinnan.com" 
            className={`hover:underline ${isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'}`}
          >
            ← back to ethankinnan.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;